<template>
  <AppLoginPageLayout>
    <div class="d-flex justify-center">
      <img
        src="@/assets/icons/logo_sourcery.svg">
    </div>
    <h1 class="form-login__container--title">
      Reset your password
    </h1>
    <p class="form-login__container--description">
      Create your new password below
    </p>
    <v-form @submit.prevent="confirmResetPassword">
      <v-text-field
        v-model="password.code"
        placeholder="Code"
        single-line
        filled
        dense
        :error-messages="verificationCodeErrors"
        :append-icon="!!verificationCodeErrors.length? 'mdi-alert-circle-outline' : ''"
        prepend-inner-icon="mdi-account-key"
        :error="!!verificationCodeErrors.length"
        autocomplete="one-time-code"
        @input="errorWithCode = null" />
      <v-text-field
        v-model="password.newPassword"
        placeholder="New Password"
        single-line
        :type="showNewPassword ? 'text' : 'password'"
        :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
        filled
        dense
        :error-messages="newPasswordErrors"
        prepend-inner-icon="mdi-lock-outline"
        :error="!!newPasswordErrors.length"
        class="mt-6"
        autocomplete="new-password"
        @click:append="showNewPassword = !showNewPassword" />
      <v-text-field
        v-model="password.repeatPassword"
        placeholder="Confirm password"
        single-line
        :type="showConfirmPassword ? 'text' : 'password'"
        :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
        filled
        dense
        :error-messages="repeatPasswordErrors"
        prepend-inner-icon="mdi-lock-outline"
        :error="!!repeatPasswordErrors.length"
        class="mt-6"
        autocomplete="new-password"
        @click:append="showConfirmPassword = !showConfirmPassword" />
      <v-row class="d-flex justify-center mt-12">
        <v-btn
          type="submit"
          color="lightBlue">
          RESET PASSWORD
        </v-btn>
      </v-row>
    </v-form>
  </AppLoginPageLayout>
</template>
<script>
import { Auth } from 'aws-amplify';
import {
  required,
  sameAs,
  minLength,
} from 'vuelidate/lib/validators';
import AppLoginPageLayout from '@/components/App/AppLoginPageLayout';
import AWS from '@/services/awsSdk';
import { mapMutations } from 'vuex';
import ErrorsText from '@/constants/errors';
export default {
  name: 'ConfirmResetPassword',
  components: {
    AppLoginPageLayout,
  },
  props: {
    email: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    errorWithCode: null,
    showNewPassword: false,
    showConfirmPassword: false,
    password: {
      code: null,
      newPassword: null,
      repeatPassword: null,
    },
  }),
  validations: {
    password: {
      newPassword: {
        required, minLength: minLength(8),
      },
      repeatPassword: {
        required, sameAs: sameAs('newPassword'),
      },
      code: {
        required, minLength: minLength(6),
      },
    },
  },
  computed: {
    verificationCodeErrors() {
      const errors = [];
      if (!this.$v.password.code.$dirty) return errors;
      if (!this.$v.password.code.minLength) {
        errors.push('Verification code must be at 6 characters long');
      }
      if (!this.$v.password.code.required) {
        errors.push(ErrorsText.ENTER_REQUIRED_VALUE);
      }
      if (this.errorWithCode) {
        errors.push(this.errorWithCode);
      }
      return errors;
    },
    newPasswordErrors() {
      const errors = [];
      if (!this.$v.password.newPassword.$dirty) return errors;
      if (!this.$v.password.newPassword.required) {
        errors.push(ErrorsText.ENTER_REQUIRED_VALUE);
      }
      if (!this.$v.password.newPassword.minLength) {
        errors.push('Password must be with 8 characters long');
      }
      return errors;
    },
    repeatPasswordErrors() {
      const errors = [];
      if (!this.$v.password.repeatPassword.$dirty) return errors;
      if (!this.$v.password.repeatPassword.required) {
        errors.push(ErrorsText.ENTER_REQUIRED_VALUE);
      }
      if (!this.$v.password.repeatPassword.sameAs) {
        errors.push('Confirm Password must be the same');
      }
      return errors;
    },
  },
  created() {
    if (!this.email) {
      this.$router.push('reset-password');
    }
  },
  methods: {
    ...mapMutations(['spinner']),
    async confirmResetPassword() {
      this.$v.password.$touch();
      if (!this.$v.password.$invalid) {
        const { email } = this;
        const { code, newPassword } = this.password;
        this.spinner(true);
        try {
          await Auth.forgotPasswordSubmit(email, code, newPassword);
          AWS.login({
            email, password: newPassword,
          }, this);
        } catch (err) {
          this.spinner(false);
          this.errorWithCode = err.message;
        }
      }
    },
  },
};
</script>
<style scoped lang="scss"></style>
